import {
  FormData,
  usePaymentOccasionQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import Banner from "@earnnest-e2-frontend/platform-ui/src/Banner/Banner"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button"
import FormField from "@earnnest-e2-frontend/platform-ui/src/FormField"
import {
  CircularIcon,
  ClipboardIcon,
} from "@earnnest-e2-frontend/platform-ui/src/Icons"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import { Formik } from "formik"
import numeral from "numeral"
import React, { useEffect, useMemo } from "react"
import * as Yup from "yup"

interface FormProps {
  paymentOccasionId: string
  paymentFormData: FormData[] // this is the initial state of the form, and when user submits this form, the values are saved in state above this form and passed back to this form when user wants to edit.
  urlFormData: FormData[] // this is the state of the form that's saved in the url (?data= param).
  onSubmit: Function
}

export default function PaymentFieldsForm({
  paymentOccasionId,
  paymentFormData,
  urlFormData,
  onSubmit,
}: FormProps) {
  const { track } = useEarnnestAnalytics()
  const paymentOccasionQuery = usePaymentOccasionQuery({
    variables: {
      id: paymentOccasionId,
    },
  })
  const paymentOccasion = paymentOccasionQuery.data?.paymentOccasion

  useEffect(() => {
    track("Payment Details Viewed")
  }, [track])

  const initialValues = useMemo(() => {
    let values = {
      amount: "",
    }
    paymentOccasion?.formFields?.forEach((field) => {
      values[field.name] = ""
    })
    paymentFormData.forEach((field) => {
      if (field.name === "amount") {
        values["amount"] = numeral(parseInt(field.value) / 100).format(
          "$0,0[.]00",
        )
      } else {
        values[field.name] = field.value || ""
      }
    })
    return values
  }, [paymentOccasion, paymentFormData])

  const validationSchema = useMemo(() => {
    let max = (paymentOccasion?.limit || 10000000) / 100
    let shape = {
      amount: Yup.string()
        .required(`Amount is required.`)
        .typeError(`Amount is required.`)
        .test("Min", `Min $48`, (x) => numeral(x).value() >= 48)
        .test(
          "Max",
          `Max ${numeral(max).format("$0,0[.]00")}`,
          (x) => numeral(x).value() <= max,
        ),
    }
    if (paymentOccasion?.direction === "OUTBOUND") {
      shape["receiverEmail"] = Yup.string()
        .email("Enter a valid email.")
        .required("Required.")
    }
    paymentOccasion?.formFields.forEach((field) => {
      let validation = Yup.string()
      if (field.required) {
        validation = validation.required(`${field.label} is required`)
      }
      if (field.type === "EMAIL") {
        validation = validation.email("Enter a valid email")
      }
      if (field.type === "URL") {
        validation = validation.url("Enter a valid url")
      }
      shape[field.name] = validation
    })
    return Yup.object().shape(shape)
  }, [paymentOccasion])

  if (!paymentOccasion) {
    return null
  }

  return (
    <Box>
      <Box pb={48}>
        <Box pb={16} style={{ alignSelf: "center" }}>
          <CircularIcon
            Icon={ClipboardIcon}
            size={64}
            iconRatio={0.6}
            backgroundColor="contrast0"
            color="contrast90"
          />
        </Box>
        <Box pb={8}>
          <Text type="heading3" style={{ textAlign: "center" }}>
            {paymentOccasion.name} details
          </Text>
        </Box>
        <Text type="baseText" style={{ textAlign: "center" }}>
          Fill out the amount and details below.
        </Text>
      </Box>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ amount, ...rest }) => {
          track("Payment Details Edited")
          onSubmit({
            amount: parseInt((numeral(amount).value() * 100).toFixed(0)),
            ...rest,
          })
        }}>
        {(formik) => (
          <form
            onSubmit={(e) => e.preventDefault()}
            style={{ display: "flex", flexDirection: "column" }}>
            <>
              <Box my={24}>
                <Text type="heading4">Amount</Text>
              </Box>
              <FormField
                name="amount"
                keyboardType="decimal-pad"
                maskType="dollars"
                label="Amount"
                autoComplete="off"
                readOnly={urlFormData.some((e) => e.name === "amount")}
              />
            </>
            {paymentOccasion.direction === "OUTBOUND" ? (
              <>
                <Box my={24}>
                  <Text type="heading4">Send to</Text>
                </Box>
                <FormField
                  name="receiverEmail"
                  label="Email address"
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  autoComplete="off"
                />
              </>
            ) : null}
            {paymentOccasion.formFields.length ? (
              <>
                <Box my={24}>
                  <Text type="heading4">Details</Text>
                </Box>
                {paymentOccasion.formFields.map((field) => (
                  <Box pb={16} key={field.name}>
                    <FormField
                      type={field.type.toLowerCase()}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      placeholder={field.placeholder}
                      autoComplete="off"
                      multiline={field.type === "TEXTAREA" ? true : undefined}
                      numberOfLines={field.type === "TEXTAREA" ? 3 : undefined}
                      maskType={
                        field.type === "NUMBER"
                          ? "number"
                          : field.type === "TEL"
                          ? "phone"
                          : undefined
                      }
                      readOnly={urlFormData.some((e) => e.name === field.name)}
                      options={field.options?.map((x) => ({
                        value: x,
                        label: x,
                      }))}
                    />
                  </Box>
                ))}
              </>
            ) : null}
            {urlFormData.some((x) => formik.errors.hasOwnProperty(x.name)) ? (
              <Banner
                kind="error"
                title="Invalid Link Data"
                message="Please reach out to your contact or to our support team to obtain a new link with valid values."
              />
            ) : null}
            <Box h={56} />
            <Button
              title="Continue"
              size="lg"
              style={{ alignSelf: "center", width: 320 }}
              readOnly={formik.submitCount > 0 && !formik.isValid}
              onPress={formik.handleSubmit}
            />
          </form>
        )}
      </Formik>
    </Box>
  )
}
